.footer {
    height: 424px;
    background-image: url("../assets/john-preaching.webp");
    background-position: 50%;
    background-size: cover;
    margin-top: 160px;
}

.footer-blur {
    height: 100%;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    justify-content: space-between;
    overflow: hidden;
}

.footer-box {
    justify-content: space-between;
    margin-top: 96px;
    display: flex;
}

.leftcolumn-1 {
    display: flex;
}

.footerlinks {
    width: 192px;
    flex-direction: column;
    display: flex;
}

.footerlink {
    color: #fff;
    margin-bottom: 16px;
    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
}

.leftcolumn-2 {
    flex-direction: column;
    justify-content: space-between;
    display: flex;
}

.downloadicon {
    height: 16px;
    margin-left: 7px;
    transform: translate(0, 3px);
}

.facebook-link {
    margin-bottom: 16px;
}

.fbicon {
    margin-right: 6px;
    transform: translate(0, 2px);
}

.sociallink {
    color: #fff;
    margin-bottom: 16px;
    font-size: 15px;
    font-weight: 400;
    text-decoration: underline;
}

.rightcolumn {
    display: flex;
}

.column-1 {
    width: 192px;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 32px;
    display: flex;
}

.footer-p {
    color: #fff;
    text-align: right;
    font-family: red-hat-display, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
}

.contacts {
    text-align: right;
    margin: 0 0 16px 0;
}

.column-2 {
    width: 160px;
}

.column-2 h1 {
    max-height: 148px;
    color: #fff;
    margin-bottom: 12px;
    font-family: warnock_prosemibold, Palatino Linotype, Book Antiqua, Palatino, serif;
    font-size: 28px;
    line-height: 118%;
}

.copyright-background {
    width: 100%;
    height: 100%;
    opacity: 1;
    filter: blur(50px);
    -webkit-text-fill-color: inherit;
    mix-blend-mode: screen;
    background-image: url("../assets/john-preaching.webp");
    background-position: 50%;
    background-size: cover;
    background-clip: content-box;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: -256px;
    display: flex;
    position: absolute;
    overflow: hidden;
    pointer-events: none;
}

.copyright {
    opacity: .65;
    color: #fff;
    text-align: center;
    mix-blend-mode: overlay;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 48px;
    font-family: red-hat-display, sans-serif;
    font-size: 15px;
    font-weight: 300;
    line-height: 22px;
}

.copyright a {
    color: #fff;
}

.footerlink.active {
    font-weight: 700;
}

#admin {
    margin-top: 14px;
    text-decoration: underline;
}

.mobile-sfp{
    display: none;
}


@media only screen and (max-width: 1260px) {

    .footer {
        height: 416px;
        background-image: url("../assets/john-preaching.webp");
        background-position: 100%;
        margin-top: 80px;
    }

    .footerlinks {
        width: auto;
    }

    .footer-box {
        margin-top: 64px;
        display: grid;
        justify-content: center;
        align-content: center;
    }

    .leftcolumn-1 {
        display: none;
    }

    .rightcolumn {
        flex-direction: column;
    }

    .footer * {
        text-align: center;

    }

    .column-1 {
        width: auto;
        margin-right: 0;
    }

    .column-2 {
        margin-top: 48px;
        width: 272px;
    }

    .contacts {
        margin: 16px 0 0 0;
    }

    .copyright {
        display: none;
    }

    .mobile-sfp{
        display: block;
        margin: 16px 0 0 0;
    }
}